body {
  margin: 0 auto;
  max-width: 1024px;
  background-color: #C00000;
  /* background-color: black; */
  color:#FFFAFB;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  color: #FFB2AC
}
invalid-feedback {
  color: #fffafbd2
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.agenda {
  background-color: #FFFAFB;
  color: black;
}
.badge-agenda {
  background-color: #C00000;
  color: #FFFAFB;
}
.badge-agenda.empty-place {
  background-color: #F2F6FF;
  cursor: pointer
}

.badge-agenda.filled-place {
  background-color: #7ee968;
  color: #fffafbd2;
  cursor: pointer
}

.badge-agenda.cleanup, .badge-agenda.filled-place {
  cursor: not-allowed;
  font-weight: bold;
}

hr {
  color :#d3d3d3
}

.modal-box {
  color: black
}
